import { useEffect, useState } from 'react';

import ReactGA from 'react-ga';
import { useLocation } from 'react-router-dom';

export const usePageTracking = () => {
  const location = useLocation();
  const [initialized, setInitialized] = useState(false);

  useEffect(() => {
    if (!window.location.href.includes("localhost")) {
      ReactGA.initialize("UA-31062630-1");
      setInitialized(true);
    }
  }, []);

  useEffect(() => {
    if (initialized) {
      ReactGA.pageview(location.pathname + location.search);
    }
  }, [initialized, location]);
};

export const GAEvent = {
  category: "",
  action: "",
  label: "",
  value: undefined,
  nonInteraction: undefined,
  transport: undefined,
};

export const logEventGA = (event) => {
  ReactGA.event(event);
};

export const logErrorGA = (description, fatal = false) => {
  ReactGA.exception({
    description,
    fatal,
  });
};
