import React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';

const ErrorFallback = ({ error, resetErrorBoundary }) => {
  const navigate = useNavigate();

  const goHome = () => {
    navigate("/");
    resetErrorBoundary();
  };

  const reset = () => resetErrorBoundary();

  return (
    <Dialog
      open
      aria-describedby="alert-dialog-description"
      aria-labelledby="alert-dialog-title"
    >
      <DialogTitle id="alert-dialog-title">Oops</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Something went wrong on our end.
        </DialogContentText>
        <b>Error Message:</b>
        <pre>{error.message}</pre>
        <DialogContentText>
          This has been logged. Try to reset to go back. If that does not work
          please navigate back to the home page.
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button autoFocus onClick={reset}>
          Reset
        </Button>
        <Button onClick={goHome}>Home</Button>
      </DialogActions>
    </Dialog>
  );
};

ErrorFallback.propTypes = {
  error: PropTypes.shape({
    message: PropTypes.string,
  }).isRequired,
  resetErrorBoundary: PropTypes.func,
};

ErrorFallback.defaultProps = {
  resetErrorBoundary: () => {},
};

export default ErrorFallback;
