import React from 'react';

import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

import MKBox from '@components/MKBox';

const Loader = () => (
  <MKBox height="100vh" width="100vw">
    <Backdrop
      open
      sx={{
        color: "#000",
        position: "absolute",
        backgroundColor: "#f0f2f5",
        zIndex: (theme) => theme.zIndex.drawer + 1,
      }}
    >
      <CircularProgress color="inherit" />
    </Backdrop>
  </MKBox>
);

export default Loader;
