/**
=========================================================
* Material Kit 2 React - v2.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2021 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import React, { lazy, Suspense, useCallback, useEffect } from 'react';

import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';

import { Navigate, Route, Routes, useLocation } from 'react-router-dom';

import Loader from '@components/Loader';
import theme from '@src/assets/theme';
import routes from '@src/routes';
import { usePageTracking } from '@utils/googleAnalytics';

const Privacy = lazy(() => import("@src/layouts/pages/legal/privacy"));
const Sitemap = lazy(() => import("@src/layouts/pages/legal/sitemap"));
const Terms = lazy(() => import("@src/layouts/pages/legal/terms"));

const otherNames = {
  "/privacy": "privacy",
  "/site-map": "sitemap",
  "/terms": "terms & conditions",
};
const App = () => {
  const { pathname } = useLocation();

  const getName = useCallback((allRoutes, path) => {
    let returnVal = "";
    for (let i = 0; i < allRoutes.length; i += 1) {
      const { collapse, name, route } = allRoutes[i];
      if (collapse) {
        returnVal = getName(collapse, path);
      }

      if (route) {
        if (path === "/") {
          break;
        }
        if (route === path) {
          returnVal = `${name} - `;
          break;
        }
      }
    }
    if (otherNames[path]) returnVal = `${otherNames[path]} - `;

    return returnVal
      .toLowerCase()
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.substring(1))
      .join(" ");
  }, []);

  usePageTracking();

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
    document.title = `${getName(routes, pathname)}The Whipple Law Firm, P.C.`;
  }, [getName, pathname]);

  const getRoutes = (allRoutes) =>
    allRoutes.map((route) => {
      if (route.collapse) {
        return getRoutes(route.collapse);
      }

      if (route.route) {
        return (
          <Route
            exact
            element={route.component}
            key={route.name}
            path={route.route}
          />
        );
      }

      return null;
    });

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Suspense fallback={<Loader />}>
        <Routes>
          {getRoutes(routes)}
          <Route element={<Privacy />} path="/privacy" />
          <Route element={<Sitemap />} path="/site-map" />
          <Route element={<Terms />} path="/terms" />
          <Route element={<Navigate to="/" />} path="*" />
        </Routes>
      </Suspense>
    </ThemeProvider>
  );
};

export default App;
